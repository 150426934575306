.vgt-wrap {
    font-family: "Nunito", "Segoe UI", arial !important;
    white-space: nowrap !important;
    box-shadow: none !important;

    .vgt-inner-wrap{
        box-shadow: none !important;
    }

}

table.vgt-table {
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
}

.vgt-wrap__footer {
    .footer__row-count__label {
        display: none !important;
    }
}

.vgt-wrap__footer {
    .footer__row-count__select {
        border: 1px solid #dcdfe6 !important;
        color: grey;
    }
}

.vgt-wrap__footer {
    .footer__navigation__page-info__current-entry {
        border: 1px solid #dcdfe6 !important;
        color: grey;
    }
}


.vgt-wrap__footer {
    .footer__navigation__page-btn {
        .chevron.right:after {
            border-left: 6px solid $primary !important;
        }
    }
}

.vgt-wrap__footer {
    .footer__navigation__page-btn {
        .chevron.left:after {
            border-right: 6px solid $primary !important;
        }
    }
}

.vgt-wrap__footer {
    .footer__row-count__select {
        font-size: 14px !important;
    }
}

.vgt-wrap__footer{
    .footer__navigation {
        font-size: 14px !important;
    }
}

.vgt-wrap__footer {
    .footer__navigation__page-btn {
        span {
            font-size: 14px !important;
        }
    }
}

a:hover {
    color: gray;
    text-decoration: none;
}

.activities {
    .activity:before {
        background-color: $primary !important;
    }
}

.shadow-primary {
    box-shadow: 0 2px 6px $primaryHover;
}

.vgt-global-search.vgt-clearfix {
    border: none !important;
    background: transparent !important;
    width: 300px;
    padding: 25px 0;
}


.footer__navigation__info {
        float: left!important;
        padding: 3px 0;
}

.export-button {
    border-radius: 30px;
}
