.table{
  min-width: 100%;
  margin-bottom: 20px;

  tbody{
    tr{
      vertical-align: middle;

      &.title{
        display: none;
      }

      .rounded-circle{
        width: 20px;
      }

      td{
        .dropdown-menu{
          width: 110px;
          min-width: 110px;
        }
      }
    }
  }
}

.vgt-wrap{
  font-size: 12px;

  .vgt-wrap__footer{
    padding: 20px 0 0 0;
    border: none;
    background: none;
  }

  .vgt-wrap__footer .footer__row-count__select{
    margin-left: 0;
  }

  .vgt-wrap__footer .footer__navigation__info, .vgt-wrap__footer .footer__navigation__page-info{
    margin: 0 0 0 16px;
  }
}