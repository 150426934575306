.auth{
  padding-top: 8%;

  .card{
    .card-header{
      h4{
        text-align: center;
        margin: 0;
        color: $gray;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  .login-brand{
    margin: 20px 0;

    img{
      width: 70%;
      margin: 0;
      padding: 0;
    }
  }
}

.bg-auth{
  background-image: url('/images/bg.jpeg') !important;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
}