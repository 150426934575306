//SWEET ALERT
.swal2-container{
  .swal2-popup{
    .swal2-actions{
      .swal2-confirm{
        background-color: #4CAF50;
      }
      .swal2-cancel{
        background-color: #BDBDBD;
      }
    } 
  }
}