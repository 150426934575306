#filtro #vs1__combobox .vs__selected-options .vs__selected {
	margin: -3px 2px 0px 88px !important;
}

#filtro #vs2__combobox .vs__selected-options .vs__selected {
	margin: -3px 2px 0px !important;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

#filtro #vs1__combobox .vs__selected-options .vs__search {

	font-weight: 600 !important;
	color: black;
	font-size: 12px !important;
	line-height: 24px !important;
	padding: 0.3rem 0.8rem !important;
	letter-spacing: .5px !important;
	margin: -3px 0 0 !important;
	text-align: center !important;
}

#filtro #vs2__combobox .vs__selected-options .vs__search {

	font-weight: 600 !important;
	color: black;
	font-size: 12px !important;
	line-height: 24px !important;
	padding: 0.3rem 0.8rem !important;
	letter-spacing: .5px !important;
	margin: -3px 0 0 !important;
	text-align: center !important;
}

.alert-field-error {
	display: inline-block;
	width: 100%;
	margin: 5px 0;
	background-color: #FAFAFA;
	padding: 10px;
	font-size: 14px;
	text-align: left;
}

.btn-new-contract {
	height: 100px;
	text-align: center;
	padding: 20px;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;

	&:hover {
		@include transition(.5s ease all);
		cursor: pointer;
		background-color: $primaryHover !important;
	}
}

// .nav-item.active {
//     .nav-link {
//         color: $primary !important;
//     }
// }

// .nav-item.active {
//     .nav-link:before {
//         background-color: $primary !important;
//     }
// }

// .nav-link {
//     &:hover {
//         color: $primaryHover !important;
//     }
// }

.page-item.active.disabled {
	.page-link {
		color: rgb(255, 255, 255);
	}
}

.page-item.disabled {
	.page-link {
		color: #6c757d;
	}
}

.page-link {
	&:hover {
		background-color: $primary !important;
		color: white !important;
	}
}

.card {
	.card-stats {
		.card-stats-item {
			.card-stats-item-label {
				margin: 0 0 20px 0;
			}
		}
	}
}

.btn.btn-danger {
	color: white !important;
}

.btn.btn-primary {
	&:active {
		background-color: $primaryHover !important;
	}
}

.card-icon.shadow-primary.bg-primary {
	background-color: $primary !important;
	box-shadow: 0 2px 6px $primaryHover !important;
}

.card.card-statistic-1 .card-header,
.card.card-statistic-2 .card-header {
	padding: 20px 5px 5px 5px;
}

.top-breadcrumb {

	.section-header-breadcrumb {
		.breadcrumb-item {
			color: $white;
			opacity: 0.7;
			float: left;

			a {
				color: $white;
			}

			&.active {
				color: $white;
				opacity: 0.2;
			}
		}
	}
}

.media-right {
	color: $primary !important;
}

.dropdown-menu.dropdown-menu-sm.dropdown-menu-right.show {
	width: 300px im !important;
}

//####################
//RESPONSIVE
@media screen and (max-width: 1024px) {}

@media screen and (min-width: 1025px) and (max-width: 1280px) {}

@media screen and (min-width: 1281px) and (max-width: 1366px) {}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
	.main-portal {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}

@media screen and (min-width: 1441px) and (max-width: 1600px) {
	.main-portal {
		padding-left: 20px !important;
		padding-right: 20px !important;

		// Alteração feita para tirar o scrool da tabela quando o zoom do navegador está em 90%
		// padding-left: 200px !important;
		// padding-right: 200px !important;
	}
}

@media screen and (min-width: 1601px) and (max-width: 1920px) {
	.main-portal {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}

@media screen and (min-width: 1921px) and (max-width: 5000px) {
	.main-portal {
		padding-left: 200px !important;
		padding-right: 200px !important;
	}
}