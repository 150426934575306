// // Body
// $body-bg: #f8fafc;

// // Typography
$font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// // Colors
// $blue: #3490dc;
// $indigo: #6574cd;
// $purple: #9561e2;
// $pink: #f66d9b;
// $red: #e3342f;
// $orange: #f6993f;
// $yellow: #ffed4a;
// $green: #38c172;
// $teal: #4dc0b5;
// $cyan: #6cb2eb;

$primary: #ec2434;
$primaryHover: #ec3444;

$gray: #565C65;

$black: #212121;
$white: #FFFFFF;

a{
  color: $primary;
}

a, .btn{
  &:hover{
    cursor: pointer;
  }
}

.bg-primary{
  background-color: $primary !important;
}

.btn-primary{
  @include transition(.5s ease all);
  background-color: $primary !important;
  border: 1px solid $primary !important;

  &:hover{
    background-color: $primaryHover !important;
    border: 1px solid $primaryHover !important;
  }
}

.card{
  &.card-primary{
    border-top: 2px solid $primary;
  }

  .card-header{
    .btn-right{
      position: absolute;
      float: right;
      right: 15px;
    }

    h4{
      color: $primary;
    }
  }
}

.text-primary{
  color: $primary !important;
}

