.dashboard{
  .card{ 
    .card-stats{
      .card-stats-item{
        .card-stats-item-label{
          margin: 0 0 10px 0;
        }
      }
    }
  }

  .height-5{
    height: 5px;
  }
}