// // Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

//MODULES
@import "./modules/mixins";
@import "./admin/_variables";

//IMPORTS
@import "./admin/_alert";
@import "./admin/_auth";
@import "./admin/_dashboard";
@import "./admin/_form";
@import "./admin/_sidebar";
@import "./admin/_table";
@import "./admin/topnav";
@import "./admin/portal";
@import "./admin/_good_table";


.btn-import {
  margin: -80px 20px 0 0;
  position: absolute;
  float: right;
  right: 40px;
}

.btn-sincroniza-marca {
  margin: -80px 20px 0 0;
  position: absolute;
  float: right;
  right: 34px;
}

.btn-sincroniza-modelo {
  margin: -80px 20px 0 0;
  position: absolute;
  float: right;
  right: 20px;
}

.access-groups {
  .list {
    .card {
      .card-body {
        padding: 5px;
        height: 270px;
        overflow-y: auto;

        .item {
          display: inline-block;
          width: 100%;
          background-color: #fafafa;
          padding: 10px 15px;
          margin: 5px 0;

          label {
            float: right;
            margin: 5px 0 0 0;
            right: 0;
          }
        }
      }

      .card-header {
        color: $primary;
        font-size: 16px;
        font-weight: bold;
        padding: 25px;
      }
    }
  }
}

.alert-table {
  padding: 5px 15px;
  border-radius: 30px;
}

.btn-view-contract {
  display: inline-block;
  background-color: #e9ecef;
  height: 42px;
  border-radius: 0.25rem;
  padding: 10px 15px;
  color: $primary;
  margin: 27px 0 0 0;
  width: 100%;
  border: 1px solid #e4e6fc;

  &:hover {
    cursor: pointer;
  }
}

.dropdown-filter {
  width: 300px;

  li {
    font-size: 14px;
  }
}

.documentation {
  iframe {
    width: 100%;
    height: 800px;
  }
}

.dropdown-menu {
  width: 230px;
}

//FOOTER DEVELOPER
.footer-developer {
  text-align: center;
  margin: 50px 0 20px 0;

  a {
    color: #757575;
    font-weight: lighter;
    font-size: 12px;
  }

  img {
    width: 16px;
    margin: -2px 5px 0 5px;
  }
}

.navbar-bg {
  background-color: $primary;
}

.pad0 {
  padding: 0;
}

.section-top {
  margin: -10px 0 20px 0;
  padding: 0;

  .btn-primary {
    margin: 0;
  }

  .section-lead {
    margin: 5px 0 0 0;
    float: left;
  }
}

.btn-download-pdf {
  transition: 0.5s ease all;
  width: 100px;
  background: #ec2434;
  font-weight: bold;
  color: #fff !important;
  border: 0 none;
  cursor: pointer;
  padding: 10px 5px;
  // margin: 0 20px 0 0;
  border-radius: 10px;
  float: right;
  right: 20px;
  text-align: center;
  width: 200px;
}

.export-options {
  float: right;
  width: 300px;
  margin: 15px 0 0 0;
  right: 15px;
  z-index: 998;
  position: absolute;

  a {
    width: 130px;
    margin: 10px;
    float: right;
  }
}

/*#chartDashboard {
  max-height: 400px;
  max-width: 100%;
}*/

.activities {
  .activity {
    .activity-detail {
      width: 100%;

      span {
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }
  }
}