.layout-3 {
  .navbar {
    &.navbar-secondary {
      background-color: $primary !important;

      .navbar-nav {
        .nav-item {
          &.active {
            .nav-link {
              &:before {
                background-color: $white !important;
              }
            }
          }

          .dropdown-menu {
            .nav-link {
              color: #868e96 !important;

              &:hover {
                color: $primary !important;
                cursor: pointer;
              }
            }
          }
        }
        .nav-link {
          color: $white !important;

          &:hover{
            opacity: 0.7;
            color: $white !important;
          }
        }
      }
    }
  }
}

.layout-3 {
  .navbar {
    &.main-navbar {
      background-color: $primary !important;

      .navbar-nav {
        .nav-item {
          &.active {
            .nav-link {
              &:before {
                background-color: $white !important;
              }
            }
          }

          .dropdown-menu {
            .nav-link {
              color: #868e96 !important;

              &:hover {
                color: $primary !important;
                cursor: pointer;
              }
            }
          }
        }
        .nav-link {
          color: $white !important;

          &:hover{
            opacity: 0.7;
            color: $white !important;
          }
        }
      }
    }
  }
}

.main-sidebar {
  .sidebar-brand {
    img {
      width: 80%;
    }
  }

  .sidebar-menu {
    margin: 55px 0 0 0;

    li {
      &.active {
        a {
          color: $primary;
        }
      }
    }
  }
}

.sidebar-mini {
  .main-sidebar {
    .sidebar-menu {
      li {
        &.active {
          a {
            background-color: $primary !important;
          }
        }
      }
    }
  }
}

.nav-portal {
  padding: 0;

  .container-fluid{
    padding-left: 0px;
    padding-right: 0px;
  }

  &.navbar-expand-lg {
    .navbar-brand {
      img {
        width: auto;
        height: 60px;
        margin-right: 1.5rem;
      }
    }

    .navbar-nav {
      .nav-item {
        &:hover {
          // opacidade do hover no dropdown referente ao arquivo top-nav-sec.blade.php
          // opacity: 0.8; 
          color: $white;
        }

        .nav-link {
          &:hover {
            opacity: 0.8;
            color: $white;
          }
        }
      }

      .nav-link {
        color: #868e96;
      }
    }
  }
}

body.layout-3 .navbar.navbar-secondary .navbar-nav > .nav-item > .nav-link:hover {
  opacity: 0.8;
  color: $white;
}

body.layout-3 .navbar.main-navbar .navbar-nav > .nav-item > .nav-link:hover {
  opacity: 0.8;
  color: $white;
}

.main-sidebar {
  .sidebar-menu {
    .dropdown-menu {
      .active {
        a {
          color: $primary !important;
        }
      }
    }
  }
}

.main-sidebar {
  .sidebar-menu {
    .dropdown-menu {
      a {
        &:hover {
          color: $primary !important;
        }
      }
    }
  }
}

@media screen and (max-width: 450px){
  .nav-portal{
    &.navbar-expand-lg{
      background-color: $primary;
      left: 0;

      .navbar-nav{
        .nav-link{
          color: $white;
        }
      }
    }
  }
}